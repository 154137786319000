import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  FltInputField,
  FltNumbericField,
  FltTextAreaField,
} from "../../Inputs/InputFields";
import DatePicker from "../../Inputs/DateFields";
import SimpleSelect from "../../Inputs/SelectOptions";
import {
  CreateRecord,
  LocalStorageInformation,
  FormatDate,
  UpdateRecord,
} from "../../SystemSettings/CommonLib";
import {
  MissionTypes,
  ApacheSeat,
  Airframe,
  AircraftSeat,
  Endpoints,
  AirFrameModels,
  DefaultDropDown,
  AllDutyPositions,
} from "../../SystemSettings/SystemSettings";
import {
  PersonalJSON,
  IAddingFlight,
  IFlight,
} from "../../SystemSettings/ProjectInterfaces";
import CheckBoxField, { CheckBoxFieldProps } from "../../Inputs/CheckBoxField";
import ClickButton from "../../Inputs/PageButtons";
import Alert from "@material-ui/lab/Alert";

export interface FlightDetailsProps {
  token: string;
  isEditFlight: boolean;
  recordInformation?: IFlight;
  updateOnEditSaveFunction?: any;
  flightData?: IFlight[];
  setFlightData?: any;
  editArrayIndex?: number;
  setToken: any;
}
export interface IAirframeModels {
  value: string;
  text: string;
  airframe_id: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);

const FlightDetails: React.SFC<FlightDetailsProps> = (props) => {
  const [aircraftModel, setAircraftModel] = useState<string | number>("1");
  const [airframeModelList, setAirframeModelList] = useState(DefaultDropDown);
  const [day, setDay] = useState(0);
  const [dutyPositionsList, setDutyPositionsList] = useState(DefaultDropDown);
  const [flightCrew, setFlightCrew] = useState("");
  const [flightDate, setFlightDate] = useState("");
  const [flightRemarks, setFlightRemarks] = useState("");
  const [from, setFrom] = useState("");
  const [hood, setHood] = useState(0);
  const [location, setLocation] = useState("");
  const [missionType, setMissionType] = useState("T");
  const [night, setNight] = useState(0);
  const [nvg, setNvg] = useState(0);
  const [nvs, setNvs] = useState(0);
  const [personalProfile, setPersonalProfile] = useState<PersonalJSON>();
  const [seat, setSeat] = useState("");
  const [selectDutyPosition, setSelectDutyPosition] = useState("");
  const [showAlertSaved, setshowAlertSaved] = useState(false); // Alert for saving data
  const [tailNumber, setTailNumber] = useState("");
  const [to, setTo] = useState("");
  const [totalHours, setTotalHours] = useState(0);
  const [weather, setWeather] = useState(0);

  // Mission Data
  const [airAssult, setAirAssult] = useState(false);
  const [amcFlight, setAmcFlight] = useState(false);
  const [atmFlight, setAtmFlight] = useState(false);
  const [bambiBucket, setBambiBucket] = useState<boolean>(false);
  const [cmf, setCmf] = useState(false);
  const [crossCountry, setCrossCountry] = useState(false);
  const [fadec, setFadec] = useState(false);
  const [flightLead, setFlightLead] = useState(false);
  const [gunnery, setGunnery] = useState(false);
  const [hoistOperations, setHoistOperations] = useState<boolean>(false);
  const [holdingPatterns, setHoldingPatterns] = useState<boolean>(false);
  const [instrumentApproach, setInstrumentApproach] = useState(false);
  const [multiShipFlight, setMultiShipFlight] = useState(false);
  const [overWater, setOverWater] = useState(false);
  const [singleEngineFailures, setSingleEngineFailures] = useState(false);
  const [slingloads, setSlingLoads] = useState<boolean>(false);
  const [terrainFlight, setTerrainFlight] = useState(false);
  const [cefs, setCefs] = useState(false);

  // List of all the useState functions for easy looping.
  const settersGroup = [
    setFadec,
    setSingleEngineFailures,
    setAmcFlight,
    setFlightLead,
    setMultiShipFlight,
    setAtmFlight,
    setCrossCountry,
    setCmf,
    setTerrainFlight,
    setInstrumentApproach,
    setOverWater,
    setGunnery,
    setAirAssult,
    setHoldingPatterns,
    setHoistOperations,
    setSlingLoads,
    setBambiBucket,
    setCefs,
  ];
  // Reseting the mission Data
  function resetMissionData() {
    settersGroup.map((func) => func(false));
  }
  const classes = useStyles();
  // Populating the airframe models listbox
  function populateAirframeModels(profile: PersonalJSON) {
    if (profile) {
      const filteredAirframes = AirFrameModels.filter(
        (airframe: IAirframeModels) => {
          airframe.value = airframe.value.toString();
          return airframe.airframe_id !== profile.primary_ac;
        }
      );
      setAirframeModelList(filteredAirframes);
    }
  }
  // Created an array of checkboxes so we can map through them to send to the component
  const missionDatas: CheckBoxFieldProps[] = [
    {
      currentValue: bambiBucket,
      valueSetter: setBambiBucket,
      name: "Bambi Bucket",
    },
    {
      currentValue: slingloads,
      valueSetter: setSlingLoads,
      name: "Sling Loads",
    },
    {
      currentValue: hoistOperations,
      valueSetter: setHoistOperations,
      name: "Hoist Operations",
    },
    {
      currentValue: cefs,
      valueSetter: setCefs,
      name: "Extended Fuel System (CEFS)",
    },
    {
      currentValue: holdingPatterns,
      valueSetter: setHoldingPatterns,
      name: "Holding Patterns",
    },
    {
      currentValue: airAssult,
      valueSetter: setAirAssult,
      name: "Air Assault",
    },
    {
      currentValue: gunnery,
      valueSetter: setGunnery,
      name: "Gunnery",
    },
    {
      currentValue: overWater,
      valueSetter: setOverWater,
      name: "Over Water",
    },
    {
      currentValue: instrumentApproach,
      valueSetter: setInstrumentApproach,
      name: "Instrument Approach",
    },
    {
      currentValue: terrainFlight,
      valueSetter: setTerrainFlight,
      name: "Terrain Flight",
    },
    {
      currentValue: cmf,
      valueSetter: setCmf,
      name: "Combat Manuevering Flight",
    },
    {
      currentValue: crossCountry,
      valueSetter: setCrossCountry,
      name: "Cross Country",
    },
    {
      currentValue: atmFlight,
      valueSetter: setAtmFlight,
      name: "ATM Flight",
    },
    {
      currentValue: multiShipFlight,
      valueSetter: setMultiShipFlight,
      name: "Multiship Flight",
    },
    {
      currentValue: flightLead,
      valueSetter: setFlightLead,
      name: "Flight Lead",
    },
    {
      currentValue: amcFlight,
      valueSetter: setAmcFlight,
      name: "AMC",
    },
    {
      currentValue: singleEngineFailures,
      valueSetter: setSingleEngineFailures,
      name: "Single Engine Failures (SEF)",
    },
    {
      currentValue: fadec,
      valueSetter: setFadec,
      name: "FADEC",
    },
  ];

  // function called when we click the Add or Update button on the form.
  function handleSubmit() {
    const data: IAddingFlight = {
      data: {
        fltDay: day.toString(),
        fltNight: night.toString(),
        fltNVS: nvs.toString(),
        fltHood: hood.toString(),
        fltType: selectDutyPosition,
        fltSeat: seat,
        NVGflt: nvg.toString(),
        fltRemarks: flightRemarks,
        location: location,
        fltDate: flightDate,
        mission: missionType,
        fltweather: weather.toString(),
        airframe_id: AirFrameModels.filter(
          (res) => res.value == aircraftModel
        )[0].airframe_id,
        terrain_flight: terrainFlight.toString().toUpperCase(),
        bambi_bucket: bambiBucket.toString().toUpperCase(),
        sling_load: slingloads.toString().toUpperCase(),
        hoist: hoistOperations.toString().toUpperCase(),
        assault: airAssult.toString().toUpperCase(),
        gunnery: gunnery.toString().toUpperCase(),
        overwater: overWater.toString().toUpperCase(),
        cmf: cmf.toString().toUpperCase(),
        cross_country: crossCountry.toString().toUpperCase(),
        atm_flight: atmFlight.toString().toUpperCase(),
        multiship: multiShipFlight.toString().toUpperCase(),
        flight_lead: flightLead.toString().toUpperCase(),
        instrumentapproach: instrumentApproach.toString().toUpperCase(),
        holding_pattern: holdingPatterns.toString().toUpperCase(),
        tail_number: tailNumber,
        ac_model: aircraftModel,
        airport_from: from,
        airport_to: to,
        crew: flightCrew,
        fadec: fadec.toString().toUpperCase(),
        SEF: singleEngineFailures.toString().toUpperCase(),
        doublebag: "FALSE",
        AMC: amcFlight.toString().toUpperCase(),
        CEFS: cefs.toString().toUpperCase(),
      },
    };

    // If we are in edit mode use the update record otherwise to create record
    if (!props.isEditFlight) {
      CreateRecord(
        Endpoints.tables.flightLog.add,
        props.token,
        data,
        savedAlert
      );
    } else {
      UpdateRecord(
        Endpoints.tables.flightLog.update,
        props.token,
        data,
        savedAlert,
        props.recordInformation !== undefined
          ? props.recordInformation.FlyID
          : undefined
      );
      updateFlightDataOnEditSave(data.data);
    }

    function savedAlert() {
      setshowAlertSaved(true);
      setTimeout(() => setshowAlertSaved(false), 5000);
    }
  }
  // Updating the flight table
  function updateFlightDataOnEditSave(newFlightRecord: IFlight) {
    console.log(newFlightRecord);
    console.log(props.editArrayIndex);
    let updatedFlightData = props.flightData;
    if (updatedFlightData !== undefined) {
      if (props.editArrayIndex !== undefined) {
        const FlightID = updatedFlightData[props.editArrayIndex].FlyID;
        newFlightRecord["FlyID"] = FlightID;
        updatedFlightData[props.editArrayIndex] = newFlightRecord;
      }
    }

    console.log(updatedFlightData);
    props.setFlightData(updatedFlightData);
  }

  //Populating fields with Edit Data
  function populateEditData() {
    if (!props.recordInformation) {
      return;
    }
    const {
      ac_model,
      airport_from,
      airport_to,
      AMC,
      assault,
      atm_flight,
      bambi_bucket,
      cmf,
      crew,
      cross_country,
      doublebag,
      fadec,
      flight_lead,
      fltDate,
      fltDay,
      fltHood,
      fltNight,
      fltNVS,
      fltRemarks,
      fltSeat,
      fltType,
      fltweather,
      gunnery,
      hoist,
      holding_pattern,
      instrumentapproach,
      location,
      mission,
      multiship,
      NVGflt,
      overwater,
      SEF,
      sling_load,
      tail_number,
      terrain_flight,
      CEFS,
    } = props.recordInformation;

    setAirAssult(assault.toLowerCase() === "true");
    setAircraftModel(ac_model);
    setAmcFlight(AMC.toLowerCase() === "true");
    setAtmFlight(atm_flight.toLowerCase() === "true");
    setBambiBucket(bambi_bucket.toLowerCase() === "true");
    setCmf(cmf.toLowerCase() === "true");
    setCrossCountry(cross_country.toLowerCase() === "true");
    setDay(+fltDay);
    setFadec(fadec.toLowerCase() === "true");
    setFlightCrew(crew);
    setFlightDate(FormatDate(fltDate));
    setFlightLead(flight_lead.toLowerCase() === "true");
    setFlightRemarks(fltRemarks);
    setFrom(airport_from);
    setGunnery(gunnery.toLowerCase() === "true");
    setHoistOperations(hoist.toLowerCase() === "true");
    setCefs(CEFS ? CEFS.toLowerCase() === "true" : false);
    setHoldingPatterns(holding_pattern.toLowerCase() === "true");
    setHood(+fltHood);
    setInstrumentApproach(instrumentapproach.toLowerCase() === "true");
    setLocation(location);
    setMissionType(mission);
    setMultiShipFlight(multiship.toLowerCase() === "true");
    setNight(+fltNight);
    setNvg(+NVGflt);
    setNvs(+fltNVS);
    setOverWater(overwater.toLowerCase() === "true");
    setSeat(fltSeat);
    setSelectDutyPosition(fltType);
    setSingleEngineFailures(SEF.toLowerCase() === "true");
    setSlingLoads(sling_load.toLowerCase() === "true");
    setTailNumber(tail_number);
    setTerrainFlight(terrain_flight.toLowerCase() === "true");
    setTo(airport_to);
    setWeather(+fltweather);
  }

  // Totaling up the AC Hours
  useEffect(() => {
    setTotalHours(
      Math.floor((+day + +night + +nvg + +nvs + +hood + +weather) * 10) / 10
    );
  }, [day, night, nvg, nvs, hood, weather]);

  // UseEffect to run onces on page load
  useEffect(() => {
    let flightPageInfo = new LocalStorageInformation(props.token);
    //Verifying that we have personal profile information in the local storage
    if (flightPageInfo.personalProfile) {
      setData(JSON.parse(flightPageInfo.personalProfile));
    } else {
      flightPageInfo.updatingPersonalProfile(setData, props.setToken);
    }

    flightPageInfo.updatingDutyPosition(setDutyData, props.setToken);

    function setData(json: any) {
      setPersonalProfile(json);
      populateAirframeModels(json);
    }
    function setDutyData(json: any) {
      setDutyPositionsList(AllDutyPositions);
      console.log(json);
    }

    if (props.isEditFlight) {
      //fetch the record to display
      populateEditData();
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">
              {" "}
              Logging Flight Time: total = {totalHours}
            </Typography>

            <p className={classes.paragraph}>
              Enter your basic flight modes here. Use decimals to indicate
              partial hours.
            </p>
            <FltNumbericField
              fieldName="Day"
              valueSetter={setDay}
              currentValue={day}
            />

            <FltNumbericField
              fieldName="Night"
              valueSetter={setNight}
              currentValue={night}
            />
            <FltNumbericField
              fieldName="NVG"
              valueSetter={setNvg}
              currentValue={nvg}
            />
            <FltNumbericField
              fieldName="NVS"
              valueSetter={setNvs}
              currentValue={nvs}
            />

            <FltNumbericField
              fieldName="Hood"
              valueSetter={setHood}
              currentValue={hood}
            />
            <FltNumbericField
              fieldName="Weather"
              valueSetter={setWeather}
              currentValue={weather}
            />

            <Grid item xs={12} sm={12}>
              <DatePicker
                title="Flight Date"
                dateValue={flightDate}
                setDateValue={setFlightDate}
              />
              <SimpleSelect
                optionSets={dutyPositionsList}
                setOptionValue={setSelectDutyPosition}
                optionValue={selectDutyPosition}
                labelTitle={"Select Duty Position"}
              />
              <SimpleSelect
                optionSets={MissionTypes}
                setOptionValue={setMissionType}
                optionValue={missionType}
                labelTitle={"Mission"}
              />
            </Grid>
            <Grid>
              <ClickButton
                clickFunction={handleSubmit}
                title={props.isEditFlight ? "Click to Update" : "Click to Add"}
              />
              {showAlertSaved ? <Alert severity="error">Saved</Alert> : <></>}
            </Grid>
          </Paper>

          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Aircraft Information:</Typography>

            <p className={classes.paragraph}>
              Information for your record. This is a good spot to help validate
              your -12 and the airframe logbook.
            </p>
            <SimpleSelect
              optionSets={airframeModelList}
              setOptionValue={setAircraftModel}
              optionValue={aircraftModel}
              labelTitle={"Select Airframe"}
            />
            <SimpleSelect
              optionSets={
                AirFrameModels.filter((res) => res.value == aircraftModel)[0]
                  .airframe_id == 1
                  ? ApacheSeat
                  : AircraftSeat
              }
              setOptionValue={setSeat}
              optionValue={seat}
              labelTitle={"Select Seat"}
            />
            <FltInputField
              fieldName="Tail Number"
              valueSetter={setTailNumber}
              currentValue={tailNumber}
            />
            <FltInputField
              fieldName="Departure"
              valueSetter={setFrom}
              currentValue={from}
            />
            <FltInputField
              fieldName="Destination"
              valueSetter={setTo}
              currentValue={to}
            />
            <FltInputField
              fieldName="Flight Crew"
              valueSetter={setFlightCrew}
              currentValue={flightCrew}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Flight Notes</Typography>

            <p className={classes.paragraph}>
              Enter information about the flight that you would like to keep
              track of.
            </p>
            <FltTextAreaField
              fieldName="Flight Remarks"
              valueSetter={setFlightRemarks}
              currentValue={flightRemarks}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Mission Information:</Typography>

            <p className={classes.paragraph}>
              Track some training events that you have done.
            </p>
            <FltInputField
              fieldName="Location Information"
              valueSetter={setLocation}
              currentValue={location}
            />
            {missionDatas.map((missionData, index) => (
              <CheckBoxField
                key={index}
                valueSetter={missionData.valueSetter}
                name={missionData.name}
                currentValue={missionData.currentValue}
              />
            ))}
            <ClickButton
              clickFunction={() => {
                resetMissionData();
              }}
              title="Reset Mission Data"
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default FlightDetails;
