import React, { useEffect } from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";
import Contact from "./Components/Contact/Contact";
import "./App.css";
import ResponsiveDrawer from "./Components/Navigation/NeoNavbar";
import CenteredGrid from "./Components/Home/NeoHome";
import Screenshots from "./Components/ScreenshotPage/Screenshots";
import Docs from "./Components/DocsPage/Docs";
import Purchase from "./Components/Purchase/Purchase";
import NeoMainForm from "./Components/UserPages/NeoMainForm";

import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

import Footer from "./Components/Footer/Footer";
import {
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
  Paper,
} from "@material-ui/core";
import CompanyAdmin from "./Components/UserPages/CompanyAdmin/CompanyAdmin";
import ScrollToTop from "./Components/ScrollToTop";
import FlightToggle from "./Components/UserPages/FlightToggle";
import { Speed } from "@material-ui/icons";
import FaceIcon from "@material-ui/icons/Face";
import FlightIcon from "@material-ui/icons/Flight";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import SemiAnnualTotals from "./Components/UserPages/AnnualReports/SemiAnnual";
import SimulatorPage from "./Components/UserPages/SimulatorLogs/SimulatorPage";
import NeoTotalPage from "./Components/UserPages/TotalsPage/NeoTotalPage";
import CompanyReports from "./Components/UserPages/CompanyReports/CompanyReports";
import SimToggle from "./Components/UserPages/SimToggle";
import Upload from "./Components/Contact/Upload";
import ReportsPage from "./Components/UserPages/ReportsPage/ReportsPage";
import NeoPersonal from "./Components/UserPages/PersonalData/NeoPersonalPage";
declare const window: any;
// https://www.color-hex.com/color-palette/4575
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#604439",
    },
    secondary: {
      main: "#41533b",
    },
    error: {
      main: "#FB4A03",
    },
    info: {
      main: "#dae0f2",
    },
    warning: {
      main: "#F1B406",
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    borderRadius: "0",
  },
}));

function App() {
  let [currentActiveNavLink, setCurrentActiveNavLink] =
    React.useState<string>("home");
  const [value, setValue] = React.useState<number>(0);

  let [token, setToken] = React.useState<string>("");
  // let [user, setUser] = React.useState<any>({})
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    let localNav: string | null = sessionStorage.getItem("currentNav");
    setCurrentActiveNavLink(localNav !== null ? localNav : "home");
    let localToken = localStorage.getItem("token");
    if (localToken != null) {
      setToken(localToken);
    } else {
      setToken("");
    }
  }, []);

  let setNewToken = (newToken: string, currentUser: any) => {
    if (localStorage.getItem("token") !== newToken) {
      localStorage.setItem("token", newToken);
      setToken(newToken);
    } else {
      localStorage.removeItem("token");
    }
  };

  function setCookie(cname: string, cvalue: any, exdays: number) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires;
    //  + ";path=/"
  }

  function getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie(cname: string) {
    var user = getCookie(cname);
    if (user !== "") {
      return user;
    } else {
      return "expired cookie";
    }
  }
  function validRoutes() {
    if (token === localStorage.getItem("token") && token !== "") {
      let routeArray = [
        {
          route: "/flight",
          jsx: <FlightToggle token={token} setToken={setToken} />,
        },
        {
          route: "/simulator",
          jsx: <SimToggle token={token} setToken={setToken} />,
        },
        {
          route: "/totals",
          jsx: <NeoTotalPage token={token} setToken={setToken} />,
        },
        {
          route: "/semiannual",
          jsx: <SemiAnnualTotals token={token} setToken={setToken} />,
        },
        {
          route: "/reports",
          jsx: <ReportsPage token={token} setToken={setToken} />,
        },
        {
          route: "/profile",
          jsx: <NeoPersonal token={token} setToken={setToken} />,
        },
      ];
      return routeArray.map((row, index) => (
        <Route path={row.route} key={index}>
          <Paper className={classes.paper}>{row.jsx}</Paper>
        </Route>
      ));
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <>
          <ScrollToTop />
          <div>
            {/* <Navigation current={currentActiveNavLink} setCurrent={setNavLink} /> */}
            <ResponsiveDrawer setToken={setToken} />

            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/login">
                {token === localStorage.getItem("token") && token !== "" ? (
                  <NeoMainForm
                    token={localStorage.getItem("token")}
                    checkCookie={checkCookie}
                    getCookie={getCookie}
                    setCookie={setCookie}
                    setToken={setToken}
                  />
                ) : (
                  <Login
                    checkCookie={checkCookie}
                    getCookie={getCookie}
                    setCookie={setCookie}
                    token={token}
                    setToken={setNewToken}
                  />
                )}
              </Route>
              <Route path="/upload">
                <Upload />
              </Route>

              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/purchase">
                <Purchase />
              </Route>
              <Route path="/docs">
                <Docs />
              </Route>
              <Route path="/screenshots">
                <Screenshots />
              </Route>

              <Route exact path="/companyadmin">
                {token === localStorage.getItem("token") && token !== "" ? (
                  <Paper className={classes.paper}>
                    <CompanyAdmin token={token} setToken={setToken} />
                  </Paper>
                ) : (
                  <Login
                    checkCookie={checkCookie}
                    getCookie={getCookie}
                    setCookie={setCookie}
                    token={token}
                    setToken={setToken}
                  />
                )}
              </Route>
              <Route exact path="/companyadmin/reports">
                {token === localStorage.getItem("token") && token !== "" ? (
                  <Paper className={classes.paper}>
                    <CompanyReports token={token} setToken={setToken} />
                  </Paper>
                ) : (
                  <Login
                    checkCookie={checkCookie}
                    getCookie={getCookie}
                    setCookie={setCookie}
                    token={token}
                    setToken={setToken}
                  />
                )}
              </Route>
              {validRoutes()}

              <Route path="/">
                <CenteredGrid />
              </Route>
            </Switch>
          </div>
          {token ? (
            <BottomNavigation
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              showLabels
              className="tab"
            >
              <BottomNavigationAction
                onClick={() => setValue(0)}
                component={Link}
                title="Personal Profile"
                to="/login/profile"
                label="Profile"
                icon={<FaceIcon />}
              />
              <BottomNavigationAction
                onClick={() => setValue(1)}
                component={Link}
                title="Flights"
                to="/flight"
                label="Flights"
                icon={<FlightIcon />}
              />
              <BottomNavigationAction
                onClick={() => setValue(2)}
                component={Link}
                title="Simulator"
                to="/simulator"
                label="Simulations"
                icon={<FlightTakeoffIcon />}
              />
              <Hidden only={["sm", "xs"]}>
                <BottomNavigationAction
                  onClick={() => setValue(3)}
                  component={Link}
                  title="SemiAnnual"
                  to="/semiannual"
                  label="Semi Annual"
                  icon={<Speed />}
                  showLabel
                />

                <BottomNavigationAction
                  onClick={() => setValue(4)}
                  component={Link}
                  title="totals"
                  to="/totals"
                  label="Totals"
                  icon={<EqualizerIcon />}
                  showLabel
                />
              </Hidden>
            </BottomNavigation>
          ) : (
            <></>
          )}
        </>

        <Footer />
      </React.Fragment>
    </MuiThemeProvider>
  );
}

export default App;
